import React from 'react';

import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { TitleIcon } from 'components/TitleIcon';
import { Typography } from 'components/DesignSystemV2/Typography';
import { SymbolsCheckMarkBig } from 'components/Icon/SVGIcons';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import TextLinkInline from 'components/TextLinkInline/TextLinkInline';

const differences = {
  linear: {
    title: 'Phương pháp Tư duy Linearthinking',
    content:
      'Phương pháp giúp học Tiếng Anh một cách logic và bản chất do DOL sáng tạo ra.',
    textLink: 'Tìm hiểu thêm',
    url: '/gioi-thieu-linearthinking'
  },
  books: {
    title: 'Tư liệu học tập độc quyền chỉ có tại DOL',
    content:
      'Tất cả sách đều do DOL tự lên nội dung và thiết kế theo tư duy riêng.',
    textLink: 'Xem sách của DOL',
    url: '/'
  },
  tech: {
    title: 'Nền tảng công nghệ toàn diện và đột phá',
    resources: [
      'Assignment',
      'Sample W/S',
      'Online test',
      'Material',
      'Dictation',
      'AI mock test',
      'Vocabulary',
      'Extra roadmap',
      'Exercises',
      'Final test'
    ],
    textLink: 'Trải nghiệm thử Super LMS',
    url: 'https://superlms.dolenglish.vn'
  }
};

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  .headline-icon {
    display: block;
  }
  .title {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
  }
`;

const Frame = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
`;

const FristFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  ${fromScreen(1144)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const SubTextBox = styled.div`
  > a,
  p {
    display: inline;
  }
`;

const SubText = ({ content, textLink, url = '/', type = '' }) => {
  return (
    <SubTextBox>
      <Typography variant="regular/16-24" color={colorsV2.black80}>
        {content}
      </Typography>{' '}
      <TextLinkInline content={textLink} type={type} url={url} />
    </SubTextBox>
  );
};

const LinearBox = styled.div`
  position: relative;
  height: 400px;
  background: ${colorsV2.green5};
  padding: 24px 16px;
  border: 1px solid ${colorsV2.green40};
  border-radius: 16px;
  overflow: hidden;

  .linear-certificate-author {
    position: absolute;
    width: 176px;
    height: 237px;
    top: 178px;
    right: 15%;
    transform: rotate(10deg);
    box-shadow: -2px -8px 24px 0px rgba(0, 0, 0, 0.06),
      -1px -5px 6px 0px rgba(0, 0, 0, 0.03), 0px 0px 1px 0px rgba(0, 0, 0, 0.02);
  }
  .linear-certificate-brand {
    position: absolute;
    width: 121px;
    height: 166px;
    bottom: -10px;
    right: 58%;
    transform: rotate(-15deg);
    box-shadow: -2px -8px 24px 0px rgba(0, 0, 0, 0.06),
      -1px -5px 6px 0px rgba(0, 0, 0, 0.03), 0px 0px 1px 0px rgba(0, 0, 0, 0.02);
  }

  &:hover {
    .linear-certificate-author {
      transform: rotate(3deg);
    }
    .linear-certificate-brand {
      transform: rotate(-10deg);
    }
  }

  ${fromScreen(776)} {
    padding: 40px;
    .linear-certificate-author {
      width: 234px;
      height: 316px;
      top: 123px;
      right: 59px;
    }
    .linear-certificate-brand {
      width: 162px;
      height: 221px;
      bottom: -60px;
      right: 300px;
    }
  }
  ${fromScreen(1144)} {
    padding: 40px;
    .linear-certificate-author {
      width: 234px;
      height: 316px;
      top: 140px;
      right: -38px;
    }
    .linear-certificate-brand {
      width: 162px;
      height: 221px;
      bottom: -60px;
      right: 178px;
    }
  }
`;

const LinearText = styled.div`
  display: grid;
  gap: 8px;
  max-width: 288px;
`;

const Linear = ({ linear }) => {
  const { title, content, textLink, url } = linear || {};
  return (
    <LinearBox>
      <LinearText>
        <Typography variant="semi-bold/18-24" tabletVariant="bold/28-36" v3>
          {title}
        </Typography>
        <SubText content={content} textLink={textLink} url={url} type="green" />
      </LinearText>
      <LazyImage
        className="linear-certificate-author"
        imgProps={{
          alt: 'linear-certificate-author'
        }}
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/26285397-9f81-4baf-8c5f-49617ca648db.png"
      />
      <LazyImage
        className="linear-certificate-brand"
        imgProps={{
          alt: 'linear-certificate-brand'
        }}
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/b2fcd29d-9236-4ff3-9584-b5ad4bd0765a.png"
      />
    </LinearBox>
  );
};

const BooksBox = styled.div`
  position: relative;
  height: 400px;
  background: ${colorsV2.primary5};
  text-align: center;
  padding: 24px 16px 0;
  border: 1px solid ${colorsV2.primary40};
  border-radius: 16px;
  overflow: hidden;
  ${SubTextBox} {
    > a {
      display: block;
      margin: auto;
    }
  }
  .ielts-4 {
    position: absolute;
    width: 140px;
    height: 190px;
    top: 53px;
    transform: rotate(-15deg);
    box-shadow: -2px -8px 24px 0px rgba(0, 0, 0, 0.06),
      -1px -5px 6px 0px rgba(0, 0, 0, 0.03), 0px 0px 1px 0px rgba(0, 0, 0, 0.02);
    border-top-right-radius: 10px;
  }
  .ielts-intensive {
    position: absolute;
    width: 174px;
    height: 237px;
    top: 15px;
    right: 113px;
    box-shadow: -2px -8px 24px 0px rgba(0, 0, 0, 0.06),
      -1px -5px 6px 0px rgba(0, 0, 0, 0.03), 0px 0px 1px 0px rgba(0, 0, 0, 0.02);
    border-top-right-radius: 10px;
  }
  .ielts-6 {
    position: absolute;
    width: 140px;
    height: 190px;
    top: 53px;
    right: 0;
    transform: rotate(15deg);
    box-shadow: -2px -8px 24px 0px rgba(0, 0, 0, 0.06),
      -1px -5px 6px 0px rgba(0, 0, 0, 0.03), 0px 0px 1px 0px rgba(0, 0, 0, 0.02);
    border-top-right-radius: 10px;
  }

  &:hover {
    .ielts-4 {
      transform: rotate(-10deg);
    }
    .ielts-intensive {
      top: 3px;
      right: 113px;
    }
    .ielts-6 {
      transform: rotate(10deg);
    }
  }

  ${fromScreen(776)} {
    height: 364px;
    padding: 32px 64px 0;
    .ielts-4 {
      width: 140px;
      height: 190px;
      top: 70px;
      left: 106px;
    }
    .ielts-intensive {
      width: 174px;
      height: 237px;
      top: 15px;
      right: 204px;
    }
    .ielts-6 {
      width: 140px;
      height: 190px;
      top: 70px;
      right: 106px;
    }
    &:hover {
      .ielts-intensive {
        top: 3px;
        right: 204px;
      }
    }
  }

  ${fromScreen(1144)} {
    padding: 32px 64px 0;
    height: 400px;
    ${SubTextBox} {
      > a {
        display: inline;
      }
    }

    .ielts-4 {
      width: 140px;
      height: 190px;
      top: 53px;
      left: 0;
    }
    .ielts-intensive {
      width: 174px;
      height: 237px;
      top: 15px;
      right: 113px;
    }
    .ielts-6 {
      width: 140px;
      height: 190px;
      top: 53px;
      right: 0;
    }
    &:hover {
      .ielts-intensive {
        top: 3px;
        right: 113px;
      }
    }
  }
`;

const BooksText = styled.div`
  display: grid;
  gap: 8px;
  .book-title {
    padding: 0 50px;
  }
  ${fromScreen(458)} {
    .book-title {
      padding: 0;
    }
  }
`;

const BookImages = styled.div`
  position: relative;
  margin-top: 16px;
`;

const Books = ({ books }) => {
  const { title, content, textLink, url } = books || {};
  return (
    <BooksBox>
      <BooksText>
        <Typography
          className="book-title"
          variant="semi-bold/18-24"
          tabletVariant="bold/28-36"
          v3
        >
          {title}
        </Typography>
        <SubText
          content={content}
          textLink={textLink}
          url={url}
          type="primary"
        />
      </BooksText>
      <BookImages>
        <LazyImage
          className="ielts-4"
          imgProps={{
            alt: 'dol-ielts-4-with-linear'
          }}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/e63437bf-13db-4184-ba98-17681049a493.png"
        />
        <LazyImage
          className="ielts-intensive"
          imgProps={{
            alt: 'dol-ielts-intensive-with-linear'
          }}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/2cf673e7-8cd2-45b5-8145-79e53128b89d.png"
        />
        <LazyImage
          className="ielts-6"
          imgProps={{
            alt: 'dol-ielts-6.5-with-linear'
          }}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/488f6325-4aa3-418d-9575-41b6bdd91943.png"
        />
      </BookImages>
    </BooksBox>
  );
};

const TechBox = styled.div`
  position: relative;
  height: 548px;
  background: ${colorsV2.blue5};
  padding: 24px 16px;
  border: 1px solid ${colorsV2.blue40};
  border-radius: 16px;
  overflow: hidden;
  .tech-desktop {
    position: absolute;
    background: ${colorsV2.white100};
    width: 353px;
    height: 426px;
    top: 320px;
    left: 82px;
    box-shadow: -2px -8px 24px 0px rgba(0, 0, 0, 0.06),
      -1px -5px 6px 0px rgba(0, 0, 0, 0.03), 0px 0px 1px 0px rgba(0, 0, 0, 0.02);
    border: 4px solid ${colorsV2.black100};
    border-radius: 8px;
  }
  .tech-mobile {
    position: absolute;
    background: ${colorsV2.white100};
    width: 90px;
    height: 193px;
    top: 396px;
    left: 16px;
    box-shadow: -2px -8px 24px 0px rgba(0, 0, 0, 0.06),
      -1px -5px 6px 0px rgba(0, 0, 0, 0.03), 0px 0px 1px 0px rgba(0, 0, 0, 0.02);
    border: 4px solid ${colorsV2.black100};
    border-radius: 8px;
  }
  &:hover {
    .tech-desktop {
      top: 310px;
    }
    .tech-mobile {
      top: 386px;
    }
  }

  ${fromScreen(776)} {
    height: 400px;
    padding: 40px;
    .tech-desktop {
      width: 629px;
      height: 759px;
      top: 67px;
      right: -492px;
      left: unset;
    }
    .tech-mobile {
      width: 160px;
      height: 344px;
      top: 202px;
      right: 82px;
      left: unset;
    }
    &:hover {
      .tech-desktop {
        top: 48px;
      }
      .tech-mobile {
        top: 193px;
      }
    }
  }

  ${fromScreen(1144)} {
    .tech-desktop {
      width: 629px;
      height: 759px;
      top: 67px;
      right: -126px;
      left: unset;
    }
    .tech-mobile {
      width: 160px;
      height: 344px;
      top: 202px;
      right: 455px;
      left: unset;
    }
    &:hover {
      .tech-desktop {
        top: 48px;
      }
      .tech-mobile {
        top: 193px;
      }
    }
  }
`;

const TechText = styled.div`
  display: grid;
  gap: 12px;
  max-width: 410px;
`;

const ResourceList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

const ResourceItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 2px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const Tech = ({ tech }) => {
  const { title, resources, textLink, url } = tech || {};
  return (
    <TechBox>
      <TechText>
        <Typography variant="semi-bold/18-24" tabletVariant="bold/28-36" v3>
          {title}
        </Typography>
        <ResourceList>
          {resources.map((eachResource, idx) => {
            return (
              <ResourceItem key={idx}>
                <SymbolsCheckMarkBig />
                <Typography variant="regular/16-24" color={colorsV2.black80}>
                  {eachResource}
                </Typography>
              </ResourceItem>
            );
          })}
        </ResourceList>
        <TextLinkInline
          url={url}
          target="_blank"
          rel="noreferrer noopener"
          content={textLink}
          type="blue"
        />
      </TechText>
      <LazyImage
        className="tech-desktop"
        imgProps={{
          alt: 'tech-desktop'
        }}
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/c9bdf601-31dc-4e4b-b57e-d8914bdccc5b.png"
      />
      <LazyImage
        className="tech-mobile"
        imgProps={{
          alt: 'tech-mobile'
        }}
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/4b467d04-fac0-4fc7-8d25-4deda73051eb.png"
      />
    </TechBox>
  );
};

const DifferencesHomeV3 = () => {
  return (
    <Main>
      <TitleIcon iconHandle="RY1uavGWQqW2aZ0GGJDo" className="title">
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Giá trị khác biệt tại DOL
        </Typography>
      </TitleIcon>
      <Frame>
        <FristFrame>
          <Linear linear={differences.linear} />
          <Books books={differences.books} />
        </FristFrame>
        <Tech tech={differences.tech} />
      </Frame>
    </Main>
  );
};

export default withHomePageSection(
  DifferencesHomeV3,
  {
    style: {
      background: colorsV2.paper
    }
  },
  {}
);
