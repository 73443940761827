/* eslint-disable react/prop-types */

import * as React from 'react';

import 'reactjs-popup/dist/index.css';

import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components/macro';
import { Link } from 'gatsby';
import { fromScreen } from 'utils/media-query/responsive.util';
import { withHomePageSection } from 'components/withHomePageSection';
import { LazyImage } from 'components/LazyImage';
import ButtonText from 'components/ButtonText';
import { Typography } from 'components/DesignSystemV2/Typography';
import CourseButtonV3 from './CourseButtonV3';
import {
  IconsLearningBooksAppleV2,
  IconsOtherFlash,
  IconsOtherShapesObjects,
  IconsOtherArrowUp,
  IconsLearningMonitorGraduateHat,
  IconsLearningDocumentCalculator,
  IconsLearningDocumentChart,
  IconsLearningChecklistPen,
  IconsCommunicationMessageChat
} from 'components/Icon/SVGIcons';

const ROUTE_COURSE = {
  'Khóa IELTS cơ bản': 'ielts-co-ban',
  'Khóa IELTS nâng cao': 'ielts-nang-cao',
  'Khóa IELTS cấp tốc': 'ielts-cap-toc',
  'Khóa TA giao tiếp': 'tieng-anh-giao-tiep',
  'IELTS Online': 'hoc-ielts-online',
  SAT: 'sat',
  TOEIC: 'toeic'
};

const COURSE_ICON = {
  'Khóa IELTS Junior': (
    <IconsLearningBooksAppleV2 size={24} color={colorsV2.primary100} />
  ),
  'Khóa IELTS cấp tốc': <IconsOtherFlash size={24} color={colorsV2.green100} />,
  'Khóa IELTS cơ bản': (
    <IconsOtherShapesObjects size={24} color={colorsV2.yellow100} />
  ),
  'Khóa IELTS nâng cao': (
    <IconsOtherArrowUp size={24} color={colorsV2.purple100} />
  ),
  'Khóa IELTS Online': (
    <IconsLearningMonitorGraduateHat size={24} color={colorsV2.yellow100} />
  ),
  SAT: <IconsLearningDocumentCalculator size={24} color={colorsV2.blue100} />,
  'GMAT/GRE': (
    <IconsLearningDocumentChart size={24} color={colorsV2.green100} />
  ),
  TOEIC: <IconsLearningChecklistPen size={24} color={colorsV2.purple100} />,
  'Phát âm': (
    <IconsCommunicationMessageChat size={24} color={colorsV2.yellow100} />
  )
};

const COURSE_COLOR = {
  'Khóa IELTS Junior': 'primary',
  'Khóa IELTS cấp tốc': 'green',
  'Khóa IELTS cơ bản': 'yellow',
  'Khóa IELTS nâng cao': 'purple',
  'Khóa IELTS Online': 'yellow',
  SAT: 'blue',
  'GMAT/GRE': 'green',
  TOEIC: 'purple',
  'Phát âm': 'yellow'
};

const COURSE_NEW = {
  'Khóa IELTS Junior': true,
  'Khóa IELTS cấp tốc': false,
  'Khóa IELTS cơ bản': false,
  'Khóa IELTS nâng cao': false,
  'Khóa IELTS Online': false,
  SAT: false,
  'GMAT/GRE': false,
  TOEIC: false,
  'Phát âm': false
};

const COURSE_LIST = [
  {
    name: 'Khóa IELTS Junior'
  },
  {
    name: 'Khóa IELTS cấp tốc'
  },
  {
    name: 'Khóa IELTS cơ bản'
  },
  {
    name: 'Khóa IELTS nâng cao'
  },
  {
    name: 'Khóa IELTS Online'
  },
  {
    name: 'SAT'
  },
  {
    name: 'GMAT/GRE'
  },
  {
    name: 'TOEIC'
  },
  {
    name: 'Phát âm'
  }
];

const groupCoursesForDisplay = groupCourses =>
  groupCourses
    .map(courseGroupMapping => {
      return {
        name: courseGroupMapping.name
      };
    })
    .reduce((mergedObj, current) => {
      return {
        ...mergedObj,
        [current.name]: current.courses
      };
    }, {});

const ListCourse = styled.div``;

const Wrapper = styled.div`
  padding: 40px 16px;
  background: ${colorsV2.white100};
  border-bottom: 1px solid ${colorsV2.gray20};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  margin-top: -502px;

  position: relative;
  .button-text {
    margin-top: 34px;
    margin: 34px auto 0;
  }
  .course-icon {
    display: none;
  }
  ${ListCourse} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    .course-button {
      display: flex;
      justify-content: center;
      padding: 10px;
      .icon-wrapper {
        display: none;
      }
      > p {
        font-size: 16px;
      }
    }
  }

  ${fromScreen(776)} {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    margin-top: -298px;
    .course-icon {
      display: block;
      position: absolute;
      top: -80px;
      left: 78%;
      transform: translate(-50%, 0) rotate(180deg);
    }

    ${ListCourse} {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      justify-content: center;
      .course-button {
        justify-content: flex-start;
        .icon-wrapper {
          display: block;
        }
        padding: 10px 0 10px 12px;
        > p {
          font-size: 16px;
        }
      }
    }
    padding: 40px;
  }

  ${fromScreen(1144)} {
    .course-icon {
      top: -80px;
      left: 42%;
    }
    margin-top: -228px;
    ${ListCourse} {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      .course-button {
        display: grid;
        justify-content: flex-start;

        .icon-wrapper {
          display: block;
        }
        padding: 16px 18px;
        > p {
          font-size: 20px;
        }
      }
    }
    padding: 40px 60px;
  }
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

export const getName = name => {
  if (name.includes('Khóa khác')) {
    return 'IELTS Online và Phát Âm';
  }

  const hasPrefix = name.startsWith('Khóa');

  if (hasPrefix) {
    return name.substr(5).trim();
  }

  return name;
};

export const CoursesLayout = ({ groupCourses, CourseDisplay }) => {
  const sortedCourses = groupCoursesForDisplay(groupCourses);
  return <CourseDisplay courses={sortedCourses} groupCourses={groupCourses} />;
};

export const TabCourses = ({ courses }) => {
  const names = Object.keys(courses);

  return (
    <>
      <Wrapper>
        <Title>
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/28-36"
            color={colorsV2.black100}
            v3
          >
            Các chương trình học tại DOL
          </Typography>
        </Title>
        <ListCourse>
          {names.map((name, key) => (
            <Link to={ROUTE_COURSE[name]} key={key}>
              <CourseButtonV3
                text={getName(name)}
                icon={COURSE_ICON[name]}
                color={COURSE_COLOR[name]}
                isNew={COURSE_NEW[name]}
              />
            </Link>
          ))}
        </ListCourse>
        <Link to="dam-bao-dau-ra">
          <ButtonText
            content="Chính sách đảm bảo đầu ra tại DOL English"
            type="primary"
          />
        </Link>

        <LazyImage
          handle="pZtoUY3mStuWmaQ5lBx6"
          className="course-icon"
          alt="icon"
        />
      </Wrapper>
    </>
  );
};

export const GCMSCourses = withHomePageSection(() => {
  // const { desktopGroupCourses } = useCoursesGroupData();
  return (
    <CoursesLayout groupCourses={COURSE_LIST} CourseDisplay={TabCourses} />
  );
});
