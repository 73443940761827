import React from 'react';

import { withHomePageSliderSection } from 'components/withHomePageSliderSection';
import { colorsV2 } from 'style/colors-v2';
import styled, { keyframes } from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import { useKeenSlider } from 'keen-slider/react';
import cl from 'classnames';
import { HeadlineIcon } from 'components/HeadlineIcon';
import ButtonText from 'components/ButtonText';
import { Link } from 'gatsby';

const BRAND_LOCATION = [
  {
    key: 'binh_thanh_d3',
    name: 'Cơ sở Bình Thạnh - D3',
    location: '24/1 Võ Oanh (D3), Bình Thạnh, P.25, TP.HCM',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/77a23673-fa5d-47b5-bb06-d4af5d462f3e.jpg'
  },
  {
    key: 'da_nang',
    name: 'Cơ sở Đà Nẵng',
    location:
      'Lầu 3 Toà nhà Thư Dung Plaza, 87 Nguyễn Văn Linh, Quận Hải Châu, Đà Nẵng',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/920e9695-1a07-4857-8ea6-72677b2c228b.jpg'
  },
  {
    key: 'binh_thanh_nguyen_van_dau',
    name: 'Cơ sở Bình Thạnh',
    location: '302 Nguyễn Văn Đậu, P11, Q.Bình Thạnh, TP.HCM',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/6dcc4164-3caa-4da0-832b-257893db3d10.PNG'
  },
  {
    key: 'quan_6',
    name: 'Cơ sở Quận 6',
    location:
      '16 Đường số 3, P.11, Q.6 (sát bệnh viện quận 6, cổng đường Chợ Lớn), TP.HCM',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/1f8e4f11-1591-47c9-96a4-bd2515619711.jpg'
  },
  {
    key: 'thanh_xuan',
    name: 'Cơ sở Thanh Xuân',
    location: 'Lầu 2, Tòa nhà Gold Tower, 275 Nguyễn Trãi, Thanh Xuân, Hà Nội.',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/bbcb9967-4edf-4ec7-a7aa-38d066964ca8.jpg'
  },
  {
    key: 'thu_duc_to_vinh_dien',
    name: 'Cơ sở Thủ Đức',
    location: '9A Tô Vĩnh Diện, Phường Linh Chiểu, Thành Phố Thủ Đức, TPHCM.',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/d9dcd1e2-ebcf-4617-83c7-943de354e359.jpg'
  },
  {
    key: 'go_vap',
    name: 'Cơ sở Gò Vấp',
    location:
      '95-97 Đường số 3, Khu Cityland Park Hills, P10, Gò Vấp, TP.HCM (đối diện Lotte Mart)',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/37047ac6-2aaf-48b0-aca6-829e55d49051.jpg'
  },
  {
    key: 'dong_da',
    name: 'Cơ sở Đống Đa',
    location: 'Tầng G, số 158 Phố Chùa Láng, P.Láng Thượng, Q.Đống Đa, Hà Nội',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/88199add-a241-4c64-8652-1c289cb60b04.PNG'
  },
  {
    key: 'phu_nhuan',
    name: 'Cơ sở Phú Nhuận',
    location: '38/6 Nguyễn Văn Trỗi, Phường 15, Quận Phú Nhuận, TPHCM.',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/00ff2066-24b2-4e4f-80dd-bb4a7ababc20.jpg'
  },
  {
    key: 'quan_7',
    name: 'Cơ sở Quận 7',
    location: '456 Nguyễn Thị Thập, P.Tân Quy, Q.7, TP.HCM',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/b94b40e9-0aca-4002-afef-c3450a1fca0f.jpg'
  },
  {
    key: 'quan_10',
    name: 'Cơ sở Quận 10',
    location: 'Hẻm 458/14, 3 Tháng 2, P12, Q. 10, TP.HCM',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/b23f9ba9-b4ae-4ebd-9910-698334cc84a6.PNG'
  },
  {
    key: 'quan_9',
    name: 'Cơ sở Thủ Đức',
    location:
      'Tầng 4 - 25B Lê Văn Việt, Phường Hiệp Phú, Thành Phố Thủ Đức (sát Vincom Plaza), TP.HCM',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/e14777fe-03b5-4aad-9f71-06c2a479afd8.PNG'
  },
  {
    key: 'tan_binh',
    name: 'Cơ sở Tân Bình',
    location: '24A Bàu Cát 2, Tân Bình, P.14, TP.HCM',
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/868c2f9e-b6c4-4b65-8d3a-4b59d1467f6a.PNG'
  }
];

const Main = styled.div`
  .section-title {
    padding: 0 16px;
    margin: 0 auto 24px;
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
    }
  }
  .headline-icon {
    display: block;
  }
  ${fromScreen(458)} {
    .section-title {
      max-width: 457px;
    }
  }

  ${fromScreen(776)} {
    .section-title {
      max-width: 712px;
      padding: 0;
      margin: 0 auto 40px;
    }
  }
  ${fromScreen(1144)} {
    .section-title {
      max-width: 100%;
    }
  }
`;

const SlideContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  .keen-slider {
    height: 220px;
  }
  .location-image {
    position: relative;
    border-radius: 16px;
    img {
      height: 100%;
    }
  }
  ${fromScreen(776)} {
    gap: 24px;
  }
`;

const Text = styled.div`
  height: 188px;
  width: 100%;
  position: absolute;
  display: grid;
  align-items: end;
  background: linear-gradient(
    180deg,
    rgba(33, 38, 45, 0) 19.31%,
    rgba(33, 38, 45, 0.8) 83.86%,
    #21262d 100%
  );
  padding: 20px;
  bottom: 0;
`;

const FadeIn = keyframes`
  0% {
    bottom: -63px;
  }
  100% {
    bottom: 0;
  }
`;

const LocationSlideItem = styled.div`
  .address-content {
    display: none;
  }
  &:hover {
    ${Text} {
      bottom: 0;
      animation: ${FadeIn} 0.5s;
    }
    .address-title {
      margin-bottom: 0;
    }
    .address-content {
      display: block;
    }
  }
`;

const MaskLeft = styled.div`
  position: absolute;
  display: none;
  height: 100%;
  width: 160px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  ${fromScreen(776)} {
    display: block;
  }
`;

const MaskRight = styled.div`
  position: absolute;
  display: none;
  height: 100%;
  width: 160px;
  right: -2px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  transform: rotate(180deg);
  ${fromScreen(776)} {
    display: block;
  }
`;

const TextLinkWrap = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  margin-top: 70px;
  ${fromScreen(776)} {
    display: none;
  }
`;

const SlideItems = ({ locations, rtl = false }) => {
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 1.5,
    spacing: 16,
    breakpoints: {
      '(min-width: 458px)': {
        slidesPerView: 2,
        spacing: 24
      },
      '(min-width: 776px)': {
        slidesPerView: 2.5,
        spacing: 24
      },
      '(min-width: 1144px)': {
        slidesPerView: 3.5,
        spacing: 24
      }
    },
    centered: true,
    loop: true,
    rtl,
    duration: 50000,
    mode: 'free-snap',
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    }
  });

  React.useEffect(() => {
    sliderRef.current.addEventListener('mouseover', () => {
      setPause(true);
    });
    sliderRef.current.addEventListener('mouseout', () => {
      setPause(false);
    });
  }, [sliderRef]);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 2000);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, slider]);

  return (
    <div ref={sliderRef} className="keen-slider">
      {locations.map((eachLocation, idx) => {
        return (
          <LocationSlideItem
            key={idx}
            className={cl('keen-slider__slide', 'location-image')}
          >
            <LazyImage src={eachLocation.image || ''} alt="avatar" />
            <Text>
              <div>
                <Typography
                  className="address-title"
                  variant="semi-bold/16-24"
                  tabletVariant="semi-bold/18-24"
                  color={colorsV2.white100}
                  v3
                >
                  {eachLocation.name}
                </Typography>
                <Typography
                  className="address-content"
                  variant="regular/14-20"
                  color={colorsV2.white80}
                >
                  {eachLocation.location}
                </Typography>
              </div>
            </Text>
          </LocationSlideItem>
        );
      })}
    </div>
  );
};

const AllBrandLocationHome = () => {
  const firstLocations = BRAND_LOCATION.slice(0, 6);
  const secondLocations = BRAND_LOCATION.slice(6);
  return (
    <Main>
      <HeadlineIcon
        iconHandle="0GYERmATmSRJWjiRPE4w"
        url="/lien-he"
        className="section-title"
        isHomePage
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Hệ thống cơ sở trên toàn quốc
        </Typography>
      </HeadlineIcon>
      <SlideContainer>
        <SlideItems locations={firstLocations} />
        <SlideItems locations={secondLocations} rtl />
        <MaskLeft />
        <MaskRight />
      </SlideContainer>
      <TextLinkWrap>
        <Link to="/lien-he">
          <ButtonText content="Xem tất cả" type="primary" />
        </Link>
      </TextLinkWrap>
    </Main>
  );
};

export default withHomePageSliderSection(
  AllBrandLocationHome,
  {
    style: {
      background: colorsV2.white100
    }
  },
  {}
);
