import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { Typography } from '../../components/DesignSystemV2/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import { HeadlineIcon } from 'components/HeadlineIcon';
import { withHomePageSliderSection } from 'components/withHomePageSliderSection';
import { useKeenSlider } from 'keen-slider/react';
import { LazyImage } from 'components/LazyImage';

const NewsAboutDolWrapper = styled.div`
  .section-title {
    margin-bottom: 48px;
    display: grid;
    justify-content: center;
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
    }
  }
  > a {
    display: none;
  }
  .headline-icon {
    display: block;
  }
`;

const SlideContainer = styled.div``;

const NewsLogoSlideItem = styled.div``;
const Box = styled.div`
  display: grid;
`;

const SlideItems = ({ news }) => {
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 3,
    spacing: 30,
    breakpoints: {
      '(min-width: 776px)': {
        slidesPerView: 4,
        spacing: 48
      },
      '(min-width: 1144px)': {
        slidesPerView: 5,
        spacing: 48
      }
    },
    centered: true,
    loop: true,
    duration: 50000,
    mode: 'free-snap',
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    }
  });

  React.useEffect(() => {
    sliderRef.current.addEventListener('mouseover', () => {
      setPause(true);
    });
    sliderRef.current.addEventListener('mouseout', () => {
      setPause(false);
    });
  }, [sliderRef]);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 2000);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, slider]);

  return (
    <div ref={sliderRef} className="keen-slider">
      {news?.map((eachNews, idx) => {
        return (
          <NewsLogoSlideItem
            key={idx}
            className={cl('keen-slider__slide', 'news-image-wrap')}
          >
            <Box>
              <LazyImage handle={eachNews?.logoHandle} alt={eachNews?.title} />
            </Box>
          </NewsLogoSlideItem>
        );
      })}
    </div>
  );
};

export const NewsAboutDolV3 = ({ news = [] }) => {
  return (
    <NewsAboutDolWrapper>
      <HeadlineIcon
        className="section-title"
        iconHandle="RY1uavGWQqW2aZ0GGJDo"
        isHomePage
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Báo chí viết về DOL
        </Typography>
      </HeadlineIcon>
      <SlideContainer>
        <SlideItems news={news} />
      </SlideContainer>
    </NewsAboutDolWrapper>
  );
};

export const GCMSNewsAboutDolV3 = withHomePageSliderSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "LandingCourse_NewsAboutDol_V2" }) {
            content
          }
        }
      }
    `);

    return <NewsAboutDolV3 news={data.gcms.jsonContanier.content} />;
  },
  {},
  {}
);
