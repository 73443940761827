import * as React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';

import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';

import cl from 'classnames';

const Main = styled.a`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  width: fit-content;

  cursor: pointer;

  &:after {
    position: absolute;
    bottom: 0px;
    left: 0px;
    content: '';
    width: 100%;
    transform: scaleX(0);
    transform-origin: 0% 50%;
    transition: transform 250ms ease-in-out;
  }

  &:hover:after {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }

  &.primary {
    > p,
    div {
      color: ${colorsV2.primary100};
    }
    &:after {
      border-bottom: solid 1px ${colorsV2.primary100};
    }
  }
  &.green {
    > p,
    div {
      color: ${colorsV2.green100};
    }
    &:after {
      border-bottom: solid 1px ${colorsV2.green100};
    }
  }
  &.blue {
    > p,
    div {
      color: ${colorsV2.blue100};
    }
    &:after {
      border-bottom: solid 1px ${colorsV2.blue100};
    }
  }
`;

const TextLinkInline = ({ content, type, url = '', ...rest }) => {
  return (
    <Main href={url} className={cl('button-text', type)} {...rest}>
      <Typography variant="regular/16-24">{content}</Typography>
    </Main>
  );
};

TextLinkInline.propTypes = {
  type: PropTypes.string,
  content: PropTypes.string,
  url: PropTypes.string
};

TextLinkInline.defaultProps = {};

export default React.memo(TextLinkInline);
