import * as React from 'react';

import cl from 'classnames';
import format from 'date-fns/format';
import vi from 'date-fns/locale/vi';
import { Link, StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import take from 'lodash/take';
import styled, { keyframes } from 'styled-components';

import { LazyImage } from 'components/LazyImage';
import { HeadlineIcon } from 'components/HeadlineIcon';
import { colorsV2 } from 'style/colors-v2';
import { useDeviceDetect3 } from 'utils/hooks/useDeviceQuery.hook';
import { IconsLogoFacebook2 } from 'components/Icon/SVGIcons';
import { withHomePageSection } from 'components/withHomePageSection';
import { fromScreen } from 'utils/media-query/responsive.util';

import { MasonryLayout } from '../Layout/MasonryLayout';
import { Typography } from 'components/DesignSystemV2/Typography';
import ButtonText from 'components/ButtonText';

/* eslint-disable react/prop-types */
const ImgAvatar = styled(LazyImage)`
  max-width: 48px;
  max-height: 48px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Main = styled.div`
  .section-title {
    margin-bottom: 24px;
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
    }
  }
  .headline-icon {
    display: block;
  }
  > a {
    width: 100%;
    display: block;
    margin-top: 24px;
    text-align: center;
    .cta-button {
      width: fit-content;
      background: transparent;
      border-radius: 8px;
    }
  }
  ${fromScreen(776)} {
    .section-title {
      margin-bottom: 40px;
    }
    > a {
      display: none;
    }
  }
  ${fromScreen(1144)} {
    margin-top: -28px;
  }
`;

const Preview = styled.div`
  position: relative;
  display: flex;
  margin-left: 26px;
  svg {
    margin-top: 2px;
    margin-left: 4px;
  }
`;

const FadeOutFB = keyframes`
0% {
  position: absolute;
  right: 100px;
}
100% {
  position: absolute;
  right: 20px;
}
`;

const FadeInFB = keyframes`
0% {
  right: 0;
}
100% {
  right: 120px;
}
`;

const FadeOut = keyframes`
  0% {
    display: flex;
    opacity: 1;
  }
  100% {
    display: none;
    opacity: 0;
  }
`;
const HeaderSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr auto;
  .card-review-header-avatar {
    margin-right: 12px;
    img {
      border-radius: 50%;
    }
  }
  .card-review-header-info {
    display: grid;
    align-items: center;
    margin-right: 8px;
    height: 100%;
    .date {
      color: ${colorsV2.black80};
    }
    .title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .card-review-fb-icon {
    position: relative;
    animation: ${FadeOutFB} 1s;
  }
`;

const ImageSection = styled.div`
  margin: 16px;

  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border: 1px solid #e9e9ec;
    border-radius: 8px;
  }
`;

const DescriptionSection = styled.div``;
const ClamppedText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &.text-only {
    -webkit-line-clamp: 15;
  }
`;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ReviewCardWrapper = styled.div`
  position: relative;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.gray20};
  border-radius: 12px;
  ${HeaderSection} {
    padding: 16px 16px 0 16px;
  }
  ${ImageSection} {
    margin-top: 16px;
  }
  ${DescriptionSection} {
    margin-top: 16px;
    padding: 0 16px 16px 16px;
  }
  ${Preview} {
    animation: ${FadeOut} 1s;
    display: none;
  }
  &:hover {
    .card-review-fb-icon {
      position: absolute;
      animation: ${FadeInFB} 1s;
    }
    ${Preview} {
      display: flex;
      animation: ${FadeIn} 1s;
    }
  }
`;

const TextLinkWrap = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  margin-top: 70px;
  ${fromScreen(776)} {
    display: none;
  }
`;
const ReviewCard = ({
  linkAvatar,
  fullName,
  dateFormatted,
  reviewContent,
  linkReview,
  linkImage
}) => {
  return (
    <ReviewCardWrapper>
      <HeaderSection>
        <div className="card-review-header-avatar">
          <ImgAvatar
            imgProps={{
              alt: 'student-review'
            }}
            width={48}
            height={48}
            handle={linkAvatar}
          />
        </div>
        <div className="card-review-header-info">
          <Typography variant="semi-bold/14-20" className="title">
            {fullName}
          </Typography>
          <Typography variant="regular/14-20" className="date">
            {dateFormatted}
          </Typography>
        </div>
        <div className="card-review-fb">
          <IconsLogoFacebook2
            className="card-review-fb-icon"
            width={20}
            height={20}
            color={colorsV2.blue100}
          />
          <Link
            href={linkReview}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <Preview>
              <ButtonText content="Xem review" type="blue" />
            </Preview>
          </Link>
        </div>
      </HeaderSection>
      {linkImage && (
        <ImageSection>
          <LazyImage
            imgProps={{
              alt: 'student-review'
            }}
            handle={linkImage}
          />
        </ImageSection>
      )}
      <DescriptionSection>
        <ClamppedText className={cl({ 'text-only': !linkImage })}>
          <Typography variant="regular/14-20">{reviewContent}</Typography>
        </ClamppedText>
      </DescriptionSection>
    </ReviewCardWrapper>
  );
};

// const limitReview = 6;

const StudentCourseReviews = ({ data, columns = 3 }) => {
  const { isMobile } = useDeviceDetect3();
  let limitReview;
  if (isMobile) {
    limitReview = 3;
  } else {
    limitReview = 6;
  }
  const reviewPaged = take(data, 1 * limitReview);
  return (
    <Main id={'course-landing-student-reviews'}>
      <HeadlineIcon
        iconHandle="RY1uavGWQqW2aZ0GGJDo"
        url="/hall-of-fame"
        className="section-title"
        isHomePage
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Review 8 cây số từ học viên DOL
        </Typography>
      </HeadlineIcon>
      <MasonryLayout breakpointCols={columns}>
        {reviewPaged.map(review => {
          const {
            id,
            date,
            fullName,
            linkReview,
            linkAvatar,
            linkCover,
            reviewContent
          } = review.node;
          const dateFormatted = format(new Date(date), 'MM/yyyy', {
            locale: vi
          });
          return (
            <ReviewCard
              key={id}
              dateFormatted={dateFormatted}
              fullName={fullName}
              linkAvatar={linkAvatar}
              linkImage={linkCover}
              linkReview={linkReview}
              reviewContent={reviewContent}
            />
          );
        })}
      </MasonryLayout>
      <TextLinkWrap>
        <Link to="/hall-of-fame">
          <ButtonText content="Xem tất cả" type="primary" />
        </Link>
      </TextLinkWrap>
    </Main>
  );
};

const studentOnlineCourseFacebookReviewsQuery = graphql`
  query studentOnlineCourseFacebookReviewHomeQuery {
    allGoogleSpreadsheetDolLandingPageReviewAll(
      filter: { listing: { eq: "listed" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          linkReview
          linkAvatar
          linkCover
          fullName
          date
          reviewContent
          listing
          classType
          program
          order
        }
      }
    }
  }
`;

const StudentReviewsMain = ({ program, bubble, columns }) => {
  return (
    <StaticQuery
      query={studentOnlineCourseFacebookReviewsQuery}
      render={data => {
        const reviews =
          data.allGoogleSpreadsheetDolLandingPageReviewAll.edges.filter(
            item => {
              let isValidClassType = item.node.program === program;
              const isNotBlank =
                (get(item, 'node.reviewContent', '') || '').trim().length > 0;
              if (program === 'all') isValidClassType = true;
              return isValidClassType && item.node.linkAvatar && isNotBlank;
            }
          );

        return (
          <StudentCourseReviews
            bubble={bubble}
            data={reviews}
            columns={columns}
          />
        );
      }}
    />
  );
};

export default withHomePageSection(StudentReviewsMain);
